<template>
  <div>
    <div class="travelTransactionPage">
      <div class="travelTransactionContainer">
        <img
          src="@/static/images/DownloadPage/img1.jpg"
          alt=""
          class="downBgImg"
        />
        <img
          src="@/static/images/DownloadPage/img2.jpg"
          alt=""
          class="downBgImg"
        />
        <img
          src="@/static/images/DownloadPage/img3.jpg"
          alt=""
          class="downBgImg"
        />
        <div class="btnContaier">
          <div
            class="androidBtn"
            @click="goDownAndroidIos"
            id="btn"
            :data-clipboard-text="clipboardText"
          ></div>
          <div
            class="iosBtn"
            id="btn2"
            @click="goDownIosBtn"
            :data-clipboard-text="ios_url"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUuid,
  getSubmitRecoveryLog,
  getRecoveryDownloadUrl,
} from "@/api/security";
import Clipboard from "clipboard";
export default {
  name: "DownloadPage",
  data() {
    return {
      clipboardText: "",
      title: "",
      configUrl: {},
      getCookie: "",
      uuid: "",
      type: 5,
      android_url: "",
      ios_url: "",
      channel_key: "",
    };
  },
  async mounted() {
    this.uuid = localStorage.getItem("MGHPC_uuid") || "";
    if (this.uuid == null || this.uuid == undefined || this.uuid == "") {
      await this.requestGetUuid();
    }
    if (JSON.stringify(this.$route.query) !== "{}") {
      let { channel_key, source } = this.$route.query;
      if (channel_key == undefined || source == undefined) {
        return;
      }
      this.channel_key = channel_key;
      await this.requestGetRecoveryDownloadUrl({
        channel_key,
      });
      let config = {
        channel_key,
        source,
        uuid: this.uuid,
        type: this.type,
      };
      this.requestGetSubmitRecoveryLog(config);
    }
  },
  methods: {
    //1-先获取uui 2-获取下载地址 3-上报 4-复制uui
    goDownAndroidIos() {
      let that = this;
      var clipBoard = new Clipboard("#btn");
      let type = this.downloadApp();
      if (type === 1) {
        this.clipboardText = "" + this.uuid;
        clipBoard.on("success", async function (e) {
          clipBoard.destroy();
          clipBoard = new Clipboard("#btn");
          await that.requestGetRecoveryDownloadUrl({
            channel_key: that.channel_key,
          });
          window.location.href = that.android_url;
        });
      } else if (type === 2) {
        this.clipboardText = this.android_url;
        clipBoard.on("success", function (e) {
          clipBoard.destroy();
          clipBoard = new Clipboard("#btn");
          that.$toast("已复制下载链接，请使用安卓手机浏览器打开");
        });
      }
    },
    goDownIosBtn() {
      let that = this;
      var clipBoard = new Clipboard("#btn2");
      let type = this.downloadApp();
      if (type === 2) {
        window.location.href = that.ios_url;
      } else if (type === 1) {
        this.clipboardText = this.ios_url;
        clipBoard.on("success", function (e) {
          clipBoard.destroy();
          clipBoard = new Clipboard("#btn");
          that.$toast("已复制下载链接，请使用苹果手机浏览器打开");
        });
      }
    },
    downloadApp() {
      const u = navigator.userAgent;
      const isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isWeixin) {
        alert("请点击右上角使用浏览器上打开");
        return 0;
      } else {
        //android端
        if (isAndroid) {
          return 1;
        }
        //ios端
        if (isIOS) {
          return 2;
        }
      }
    },
    async requestGetSubmitRecoveryLog(config) {
      try {
        const result = await getSubmitRecoveryLog(config);
        if (result.code === 20000 || result.status_code === 1000) {
        } else {
          this.$message.error(result.message);
        }
      } catch (error) {
        this.$message.error("请求失败");
      }
    },
    async requestGetUuid() {
      try {
        const result = await getUuid();
        if (result.code === 20000 || result.status_code === 1000) {
          localStorage.setItem("MGHPC_uuid", result.data);
          this.uuid = result.data;
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
    async requestGetRecoveryDownloadUrl(config) {
      try {
        const result = await getRecoveryDownloadUrl(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let { android_url, ios_url } = result.data;
          this.android_url = android_url;
          this.ios_url = ios_url;
        } else {
          this.$message.error(result.message);
        }
      } catch (error) {
        this.$message.error("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.travelTransactionPage {
  background-color: #261568;
  padding-bottom: 30px;
  .travelTransactionContainer {
    width: 100vw;
    position: relative;
    .downBgImg {
      width: 100%;
      display: block;
    }
    .btnContaier {
      width: 100%;
      position: fixed;
      bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: env(safe-area-inset-bottom);
      .androidBtn {
        width: 165px;
        height: 57px;
        background-image: url("~@/static/images/DownloadPage/android_btn.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 11px;
      }
      .iosBtn {
        width: 165px;
        height: 57px;
        background-image: url("~@/static/images/DownloadPage/ios_btn.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
img {
  border: 0;
  // vertical-align: middle;
}
</style>
